<template>
  <v-stepper v-model="step" vertical>
    <v-stepper-step :complete="step > 1" step="1">{{ $t('step1custTitle') }}</v-stepper-step>
    <v-stepper-content step="1">
      <v-card outlined class="mb-12" min-height="5vh">
        <v-card-text>
          <v-row>
            <v-col cols="12" md="2" sm="12" class="centered" v-t="'sector'"></v-col>
            <v-col cols="12" md="8" sm="12" class="centered">
              <v-tabs grow centered v-model="localCustomer.salesSector">
                <v-tab class="pa-0 ma-0" style="min-width:0px" />
                <v-tab v-for="(el) in dropdownSectors" :key="el.id" @click="handleInput({field:'salesSectorId', ...el})">{{ el.text }}</v-tab>
                <v-tab class="pa-0 ma-0" style="min-width:0px" />
              </v-tabs>
            </v-col>
            <v-col cols="12" md="2" sm="12" class="centered">
              <v-menu>
                <template v-slot:activator="{ on: menu, attrs }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on: tooltip }">
                      <v-btn icon color="primary" dark v-bind="attrs" v-on="{ ...tooltip, ...menu }">
                        <v-icon>mdi-information</v-icon>
                      </v-btn>
                    </template>
                    <span v-t="'infoSector'"></span>
                  </v-tooltip>
                </template>
              </v-menu>
            </v-col>
            <v-col cols="12" md="2" sm="12" class="centered" v-if="!!localCustomer.salesSectorId" v-t="'channel'"></v-col>
            <v-col cols="12" md="8" sm="12" class="centered" v-if="!!localCustomer.salesSectorId">
              <v-tabs grow centered v-model="localCustomer.salesChannel">
                <v-tab class="pa-0 ma-0" style="min-width:0px" />
                <v-tab v-for="(el) in dropdownChannels" :key="el.id" @click="handleInput({field:'salesChannelId', ...el})">{{ el.text }}</v-tab>
                <v-tab class="pa-0 ma-0" style="min-width:0px" />
              </v-tabs>
            </v-col>
            <v-col cols="12" md="2" sm="12" class="centered" v-if="!!localCustomer.salesSectorId">
              <v-menu>
                <template v-slot:activator="{ on: menu, attrs }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on: tooltip }">
                      <v-btn icon color="primary" dark v-bind="attrs" v-on="{ ...tooltip, ...menu }">
                        <v-icon>mdi-information</v-icon>
                      </v-btn>
                    </template>
                    <span v-t="'infoChannel'"></span>  
                  </v-tooltip>
                </template>
              </v-menu>
            </v-col>
            <v-col cols="12" md="2" sm="12" class="centered" v-if="!!localCustomer.salesChannelId" v-t="'purchaseCategory'"></v-col>
            <v-col cols="12" md="8" sm="12" class="centered" v-if="!!localCustomer.salesChannelId">
              <v-tabs grow centered v-model="localCustomer.customerPurchaseCategory">
                <v-tab class="pa-0 ma-0" style="min-width:0px" />
                <v-tab v-for="(el) in customerPurchaseCategoriesDropdown" :key="el.id" @click="handleInput({field:'customerPurchaseCategoryId', ...el})">{{ el.text }}</v-tab>
                <v-tab class="pa-0 ma-0" style="min-width:0px" />
              </v-tabs>
            </v-col>
            <v-col cols="12" md="2" sm="12" class="centered" v-if="!!localCustomer.salesChannelId">
              <v-menu>
                <template v-slot:activator="{ on: menu, attrs }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on: tooltip }">
                      <v-btn icon color="primary" dark v-bind="attrs" v-on="{ ...tooltip, ...menu }">
                        <v-icon>mdi-information</v-icon>
                      </v-btn>
                    </template>
                    <span v-t="'infoPurchaseCategory'"></span>
                  </v-tooltip>
                </template>
              </v-menu>
            </v-col>
            <v-col cols="12" md="8" offset-md="2" sm="12" v-if="!!localCustomer.customerPurchaseCategoryId && (this.addGroupInformationForId.includes(this.localCustomer.salesChannelId) || this.addGroupInformationForId.includes(this.localCustomer.salesChannelId))">
              <AutoComplete 
                :items="dropdownGroupIdentifiers" 
                @emitUpdate="handleInput" 
                :isEdit="true" 
                :disabled="false" 
                :field="'customerGroupIdentifierTagId'" 
                :value="localCustomer.customerGroupIdentifierTagId" 
                :loading="false" 
                :clearable="true" 
                :placeholder="$t('groupIdentifier')"
              ></AutoComplete>
            </v-col>

            <v-col cols="12" md="8" offset-md="2" sm="12" v-if="!!localCustomer.customerPurchaseCategoryId">
              <AutoComplete v-if="this.addGroupInformationForId.includes(this.localCustomer.salesChannelId)" :items="getParents()" @emitUpdate="handleInput" :isEdit="true" :disabled="false" :field="'parentCode'" :value="localCustomer.parentCode" :loading="false" :clearable="true" :placeholder="$t('parentCode')"></AutoComplete>
              <!-- style="background: #f5f5f5;" -->
              <!-- <Alert v-else message="Parent not available."></Alert> -->
            </v-col>

          </v-row>
        </v-card-text>
      </v-card>
      <v-btn class="mr-2 fixed-width" :disabled="!localCustomer.customerPurchaseCategoryId" color="primary" @click="next(2)" v-t="'next'"></v-btn>
    </v-stepper-content>

    <!-- <v-stepper-step :complete="step > 2" step="2">Group Identifiers</v-stepper-step>
    <v-stepper-content step="2">
      <v-card outlined class="mb-12" min-height="5vh">
        <v-card-text>
          <v-row>
            <v-col cols="12" md="6" offset-md="3" sm="12">
              <AutoComplete
                :items="dropdownGroupIdentifiers"
                @emitUpdate="handleInput"
                :isEdit="true"
                :disabled="false"
                :field="'customerGroupIdentifierTagId'"
                :value="localCustomer.customerGroupIdentifierTagId"
                :loading="false"
                :clearable="true"
                placeholder="Group Identifier"
              ></AutoComplete>
            </v-col>
            <v-col cols="12" md="6" offset-md="3" sm="12">
              <AutoComplete
                v-if="this.localCustomer.myCustomerType == 'CHAIN'"
                :items="getParents()"
                @emitUpdate="handleInput"
                :isEdit="true"
                :disabled="false"
                :field="'parentCode'"
                :value="localCustomer.parentCode"
                :loading="false"
                :clearable="true"
                placeholder="Parent Code"
              ></AutoComplete>
              <Alert v-else message="Parent not available."></Alert>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-btn class="mr-2 fixed-width" color="secondary" @click="step = 1" outlined>Previous</v-btn>
      <v-btn class="mr-2 fixed-width" color="primary" @click="step = 3">Next</v-btn>
    </v-stepper-content> -->

    <v-stepper-step :complete="step > 2" step="2">{{ $t('step2custTitle') }}</v-stepper-step>
    <v-stepper-content step="2">
      <v-card outlined class="mb-12" min-height="5vh">
        <v-card-text>
          <v-row>
            <v-col cols="12" md="3" sm="12">
              <TextField @emitUpdate="handleInput" :isEdit="true" :disabled="false" :value="localCustomer.businessName" :loading="false" :field="'businessName'" :placeholder="getPlaceholder('businessName')"></TextField>
            </v-col>
            <v-col cols="12" md="3" sm="12">
              <TextField @emitUpdate="handleInput" :isEdit="true" :disabled="false" :field="'shopName'" :value="localCustomer.shopName" :loading="false" :placeholder="getPlaceholder('shopName')"></TextField>
            </v-col>
            <v-col cols="12" sm="12" md="3">
              <AutoComplete :items="dropdownCustomers" @emitUpdate="handleInput" :isEdit="true" :disabled="false" :value="localCustomer.defaultInvoicingCustomerId" :loading="false" crea :field="'defaultInvoicingCustomerId'" :placeholder="getPlaceholder('invoicingCustomerId')" :clearable="true"></AutoComplete>
            </v-col>
            <v-col cols="12" md="3" sm="12">
              <TextField @emitUpdate="handleInput" :rules="emailRules" :required="true" :isEdit="true" :disabled="false" :field="'email'" :value="localCustomer.email" :loading="false" :placeholder="getPlaceholder('email')"></TextField>
            </v-col>
            <v-col cols="12" sm="12" md="3">
              <AutoComplete :items="dropdownCountries" @emitUpdate="handleInput" :isEdit="true" :disabled="false" :value="localCustomer.country" :loading="false" crea :field="'country'" :placeholder="getPlaceholder('country')" :clearable="true"></AutoComplete>
            </v-col>
            <v-col cols="12" md="3" sm="12" v-if="!localCustomer.parentCode">
                  <TextField @emitUpdate="handleInput" :isEdit="true" :disabled="false" :field="'vatNumber'" :value="localCustomer.vatNumber" :loading="false" :placeholder="getPlaceholder('vatNumber')"></TextField>
            </v-col>
            <v-col cols="12" md="3" sm="12" v-else>
              <v-row>
                <v-col cols="8" md="8" sm="8">
                  <TextField @emitUpdate="handleInput" :isEdit="true" :disabled="false" :field="'vatNumber'" :value="localCustomer.vatNumber" :loading="false" :placeholder="getPlaceholder('vatNumber')"></TextField>
                </v-col>
                <v-col cols="4" md="4" sm="4">
                  <v-btn class="text-wrap" block color="secondary" @click="copyVatNumberFromParent" outlined v-t="'copyFromParent'"></v-btn>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="3" sm="12">
              <TextField @emitUpdate="handleInput" :isEdit="true" :disabled="false" :field="'fiscalCode'" :value="localCustomer.fiscalCode" :loading="false" :placeholder="getPlaceholder('fiscalCode')"></TextField>
            </v-col>
            <v-col cols="12" md="3" sm="12">
              <TextField @emitUpdate="handleInput" :isEdit="true" :disabled="false" :field="'phone'" :value="localCustomer.phone" :loading="false" :placeholder="getPlaceholder('phone')"></TextField>
            </v-col>
            <v-col cols="12" md="12" sm="12">
              <TextAreaField @emitUpdate="handleInput" :isEdit="true" :disabled="false" :field="'notes'" :value="localCustomer.notes" :loading="false" placeholder="Notes"></TextAreaField>
            </v-col>
            <v-col cols="12" md="4" sm="12">
              <AutoComplete :items="dropdownVatPercentages" @emitUpdate="handleInput" :isEdit="true" :disabled="false" :field="'defaultVatRate'" :value="localCustomer.defaultVatRate" :loading="false" :clearable="true" :placeholder="getPlaceholder('defaultVatRate')"></AutoComplete>
            </v-col>
            <v-col cols="12" md="4" sm="12">
              <AutoComplete
                :items="paymentTermsDropdown"
                @emitUpdate="handleInput"
                :isEdit="true"
                :disabled="false"
                :field="'defaultPaymentTermId'"
                :value="localCustomer.defaultPaymentTermId"
                :loading="false"
                :clearable="true"
                :placeholder="getPlaceholder('defaultPaymentTermId')"
              ></AutoComplete>
            </v-col>
            <v-col cols="12" md="4" sm="12">
              <AutoComplete
                :items="paymentMethodsDropdown"
                @emitUpdate="handleInput"
                :isEdit="true"
                :disabled="!paymentMethodsDropdown.length"
                :field="'defaultPaymentMethodId'"
                :value="localCustomer.defaultPaymentMethodId"
                :loading="false"
                :clearable="true"
                :placeholder="getPlaceholder('defaultPaymentMethodId')"
              ></AutoComplete>
            </v-col>
            <v-col cols="12" sm="12" md="6" v-if="showIbanSDI">
              <TextField
                @emitUpdate="handleInput"
                :isEdit="true"
                :disabled="false"
                :value="localCustomer.iban"
                :loading="false"
                :field="'iban'"
                :placeholder="getPlaceholder('iban')"
              ></TextField>
            </v-col>
            <v-col cols="12" sm="12" md="3" v-if="showIbanSDI">
              <TextField
                @emitUpdate="handleInput"
                :isEdit="true"
                :disabled="false"
                :value="localCustomer.sdiCode"
                :loading="false"
                :field="'sdiCode'"
                :placeholder="getPlaceholder('sdi')"
              ></TextField>
            </v-col>
            <v-col cols="12" sm="12" md="3" v-if="showIbanSDI">
              <TextField
                @emitUpdate="handleInput"
                :isEdit="true"
                :disabled="false"
                :value="localCustomer.accountingEmail"
                :loading="false"
                :field="'accountingEmail'"
                :rules="emailRules"
                :placeholder="getPlaceholder('accountingEmail')"
              ></TextField>
            </v-col>
            <v-col cols="12" md="12" sm="12">
              <TextAreaField
                @emitUpdate="handleInput"
                :isEdit="true"
                :disabled="false"
                :field="'administrativeNotes'"
                :value="localCustomer.administrativeNotes"
                :loading="false"
                :placeholder="$t('internalNotes')"
              ></TextAreaField>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-btn class="mr-2 fixed-width" color="secondary" @click="step = 1" outlined v-t="'previous'"></v-btn>
      <v-btn class="mr-2 fixed-width" color="primary" @click="step = 3" :disabled="invalidStep3" v-t="'next'"></v-btn>
      <v-btn class="mr-2 fixed-width" color="success" @click="save" :disabled="invalidStep3" v-t="'confirm'"></v-btn>
    </v-stepper-content>

    <v-stepper-step :complete="step > 3" step="3">{{ $t('address') }}</v-stepper-step>
    <v-stepper-content step="3">
      <v-card outlined class="mb-12" min-height="5vh">
        <v-card-text>
          <v-row>
            <!-- <v-col cols="12" sm="12" md="4">
              <AutoComplete
                @emitUpdate="handleLocation"
                :isEdit="true"
                :disabled="false"
                :value="selectedLocation.geonameCountryCode"
                :loading="false"
                autocomplete="nope"
                :field="'geonameCountryCode'"
                :items="dropdownCountries"
                placeholder="Country"
              ></AutoComplete>
            </v-col> -->
            <v-col cols="12" sm="10" md="3" v-if="mappedPostalCodes.length > 0">
              <AutoComplete
                @emitUpdate="handleLocation"
                class="address-text"
                :isEdit="true"
                clearable
                :disabled="!selectedLocation.geonameCountryCode"
                :value="selectedLocation.postalCode"
                :loading="isLoadingPostalCodes"
                autocomplete="nope"
                :field="'postalCode'"
                :items="mappedPostalCodes"
                :placeholder="$t('postalCode')"
              ></AutoComplete>
            </v-col>
            <v-col cols="12" sm="2" md="1" v-if="mappedPostalCodes.length > 0">
              <v-icon @click="clearPostalCodes()">mdi-close</v-icon>
            </v-col>
            <v-col cols="12" sm="12" md="4" v-else>
              <TextField
                @emitUpdate="handleLocation"
                class="address-text"
                clearable
                :isEdit="true"
                :disabled="!selectedLocation.geonameCountryCode"
                :value="selectedLocation.postalCode"
                autocomplete="nope"
                :loading="false"
                :field="'postalCode'"
                :placeholder="$t('postalCode')"
              ></TextField>
            </v-col>
            <v-col cols="12" sm="12" md="4">
              <TextField
                @emitUpdate="handleLocation"
                :isEdit="true"
                :disabled="false"
                :value="selectedLocation.city"
                autocomplete="nope"
                :loading="false"
                :field="'city'"
                :placeholder="$t('city')"
              ></TextField>
            </v-col>
            <v-col cols="12" sm="12" md="4">
              <AutoComplete
                @emitUpdate="handleLocation"
                :isEdit="true"
                :disabled="false"
                :value="selectedLocation.geonameRegionCode"
                :loading="false"
                autocomplete="nope"
                :field="'geonameRegionCode'"
                :items="dropdownRegions"
                :placeholder="$t('region')"
              ></AutoComplete>
            </v-col>
            <v-col cols="12" sm="12" md="4">
              <AutoComplete
                @emitUpdate="handleLocation"
                :isEdit="true"
                :disabled="false"
                :value="selectedLocation.geonameProvinceCode"
                :loading="false"
                :field="'geonameProvinceCode'"
                autocomplete="nope"
                :items="dropdownProvinces"
                :placeholder="$t('province')"
              ></AutoComplete>
            </v-col>
            <v-col cols="12" sm="12" md="8">
              <TextField
                @emitUpdate="handleLocation"
                :isEdit="true"
                :disabled="false"
                :value="selectedLocation.address"
                autocomplete="nope"
                :loading="false"
                :field="'address'"
                :placeholder="$t('address')"
              ></TextField>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <v-row>
                <v-col cols="12" sm="12" md="4">
                  <TextAreaField
                    @emitUpdate="handleLocation"
                    :isEdit="true"
                    :disabled="false"
                    :field="'note'"
                    :value="selectedLocation.note"
                    :loading="false"
                    :placeholder="$t('note')"
                  ></TextAreaField>
                </v-col>
                <v-col cols="12" sm="12" md="8">
                  <v-row>
                    <v-col cols="12" sm="12" md="4">
                      <TextField
                        @emitUpdate="handleLocation"
                        :isEdit="true"
                        :disabled="false"
                        :value="selectedLocation.name"
                        :loading="false"
                        autocomplete="nope"
                        :field="'name'"
                        :placeholder="$t('name')"
                      ></TextField>
                    </v-col>
                    <v-col cols="12" sm="12" md="4">
                      <TextField
                        @emitUpdate="handleLocation"
                        :isEdit="true"
                        :disabled="false"
                        :value="selectedLocation.phone"
                        :loading="false"
                        autocomplete="nope"
                        :field="'phone'"
                        :placeholder="$t('phone')"
                      ></TextField>
                    </v-col>
                    <v-col cols="12" sm="12" md="4">
                      <TextField
                        @emitUpdate="handleLocation"
                        :isEdit="true"
                        :disabled="false"
                        :value="selectedLocation.email"
                        :loading="false"
                        autocomplete="nope"
                        :field="'email'"
                        :placeholder="$t('email', {r:''})"
                      ></TextField>
                    </v-col>
                    <v-col cols="12" sm="12" md="3">
                      <AutoComplete
                          @emitUpdate="handleLocation"
                          :isEdit="true"
                          :disabled="false"
                          :value="localCustomer.nikitaTimeZoneId"
                          :loading="false"
                          :field="'nikitaTimeZoneId'"
                          autocomplete="nope"
                          :items="dropdownTimezones"
                          :placeholder="$t('timeZone')"
                      ></AutoComplete>
                    </v-col>
                      <!--<CheckBoxField
                        @emitUpdate="handleLocation"
                        :isEdit="true"
                        :disabled="false"
                        :field="'active'"
                        :value="selectedLocation.active"
                        :loading="false"
                        placeholder="Active"
                      ></CheckBoxField>
                    </v-col>
                    <v-col cols="12" sm="12" md="3">
                      <CheckBoxField
                        @emitUpdate="handleLocation"
                        :isEdit="true"
                        :disabled="false"
                        :field="'default'"
                        :value="selectedLocation.default"
                        :loading="false"
                        placeholder="Default"
                      ></CheckBoxField>
                    </v-col>
                    <v-col cols="12" sm="12" md="3">
                      <CheckBoxField
                        @emitUpdate="handleLocation"
                        :isEdit="true"
                        :disabled="false"
                        :field="'visibility'"
                        :value="selectedLocation.visibility"
                        :loading="false"
                        placeholder="Visible"
                      ></CheckBoxField>
                    </v-col>
                    <v-col cols="12" sm="12" md="3">
                      <CheckBoxField
                        @emitUpdate="handleLocation"
                        :isEdit="true"
                        :disabled="false"
                        :field="'engrave'"
                        :value="selectedLocation.engrave"
                        :loading="false"
                        placeholder="Has Engraver"
                      ></CheckBoxField>
                    </v-col> -->
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-btn class="mr-2 fixed-width" color="secondary" @click="step = 2" outlined v-t="'previous'"></v-btn>
      <v-btn class="mr-2 fixed-width" color="primary" @click="step = 4" v-t="'next'"></v-btn>
      <v-btn class="mr-2 fixed-width" color="success" @click="save" v-t="'confirm'"></v-btn>
    </v-stepper-content>

    <v-stepper-step :complete="step > 4" step="4">{{ $t('step4custTitle') }}</v-stepper-step>
    <v-stepper-content step="4">
      <v-card outlined class="mb-12" min-height="5vh">
        <v-card-text>
          <v-row>
            <v-col cols="12" md="6" v-if="localCustomer.myCustomerType !== 'POTEN'">
              <AutoComplete
                @emitUpdate="handleCatalog"
                :isEdit="true"
                :disabled="!!localCustomer.parentCode || !showFamilies"
                :value="selectedCatalog.familyId"
                :loading="false"
                :field="'familyId'"
                :items="getFamiliesMapped()"
                :placeholder="$t('family')"
              ></AutoComplete>
            </v-col>
            <v-col cols="12" md="6" v-if="localCustomer.myCustomerType !== 'POTEN'">
              <AutoComplete
                @emitUpdate="handleCatalog"
                :isEdit="true"
                :disabled="!!localCustomer.parentCode"
                :value="selectedCatalog.priceCatalogId"
                :loading="false"
                :field="'priceCatalogId'"
                :items="priceCatalogDropdown"
                :placeholder="$t('catalog')"
              ></AutoComplete>
            </v-col>
            <v-col cols="12" md="12" sm="12" v-if="localCustomer.myCustomerType !== 'POTEN'">
              <v-divider></v-divider>
            </v-col>
            <!-- <v-col cols="12" sm="12" md="3" v-if="showIbanSDI">
              <TextField
                @emitUpdate="handleInput"
                :isEdit="true"
                :disabled="false"
                :field="'sdiCode'"
                :value="localCustomer.sdiCode"
                :loading="false"
                placeholder="SDI Code"
              ></TextField>
            </v-col> -->
            <v-col cols="12" sm="12" md="3">
              <AutoComplete
                :items="dropdownLanguages"
                @emitUpdate="handleInput"
                :isEdit="true"
                :disabled="false"
                :field="'orderLanguage'"
                :value="localCustomer.orderLanguage"
                :loading="false"
                :placeholder="$t('orderLanguage')"
              ></AutoComplete>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-btn class="mr-2 fixed-width" color="secondary" @click="step = 3" outlined v-t="'previous'"></v-btn>
      <v-btn class="mr-2 fixed-width" color="primary" @click="step = 5" v-t="'next'"></v-btn>
      <v-btn class="mr-2 fixed-width" color="success" @click="save" v-t="'confirm'"></v-btn>
    </v-stepper-content>

    <v-stepper-step :complete="step > 5" step="5">{{ $t('ownership') }}</v-stepper-step>
    <v-stepper-content step="5">
      <v-card outlined class="mb-12" min-height="5vh">
        <v-card-text class="mt-4">
          <v-row>
            <v-col cols="12" sm="12">
              <div>
                <label v-t="'family'"></label>
                <div class="inf-font">
                  <AutoComplete
                    :value="selectedOwnership.familyId"
                    :disabled="!showFamilies"
                    :items="familiesDropdown"
                    :field="'familyId'"
                    :isEdit="true"
                    @emitUpdate="handleOwnership"
                  ></AutoComplete>
                </div>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="3" v-if="showSubAgent">
              <div>
                <label v-t="'subAgent'"></label>
                <div class="inf-font">
                  <AutoComplete
                    :value="selectedOwnership.subagentId"
                    :items="agentsDropdown"
                    :field="'subagentId'"
                    :isEdit="true"
                    @emitUpdate="handleOwnership"
                  ></AutoComplete>
                </div>
              </div>
            </v-col>
            <v-col cols="12" sm="3">
              <div>
                <label v-t="'agent'"></label>
                <div class="inf-font">
                  <AutoComplete :value="selectedOwnership.agentId" :items="agentsDropdown" :field="'agentId'" :isEdit="true" @emitUpdate="handleOwnership"></AutoComplete>
                </div>
              </div>
            </v-col>
            <v-col cols="12" sm="3">
              <div>
                <label v-t="'supervisorAgent'"></label>
                <div class="inf-font">
                  <AutoComplete :value="selectedOwnership.supervisorAgentId" :items="agentsDropdown" :field="'supervisorAgentId'" :isEdit="true" @emitUpdate="handleOwnership"></AutoComplete>
                </div>
              </div>
            </v-col>
            <v-col cols="12" sm="3">
              <div>
                <label v-t="'chiefAgent'"></label>
                <div class="inf-font">
                  <AutoComplete :value="selectedOwnership.chiefAgentId" :items="agentsDropdown" :field="'chiefAgentId'" :isEdit="true" @emitUpdate="handleOwnership"></AutoComplete>
                </div>
              </div>
            </v-col>
            <v-col cols="12" sm="3">
              <div>
                <label v-t="'commercialSpecialist'"></label>
                <div class="inf-font">
                  <AutoComplete :value="selectedOwnership.commercialSpecialistId" :items="commercialSpecialistsDropdown" :field="'commercialSpecialistId'" :isEdit="true" @emitUpdate="handleOwnership"></AutoComplete>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-card-text>  
      </v-card>
      <v-btn class="mr-2 fixed-width" color="secondary" @click="step = 4" outlined v-t="'previous'"></v-btn>
      <v-btn class="mr-2 fixed-width" color="success" @click="save" v-t="'confirm'"></v-btn>
    </v-stepper-content>
  </v-stepper>
</template>

<script>
import { call, get, sync } from "vuex-pathify";
import TextField from "@/components/utility/TextField";
import Autocomplete from "@/components/utility/AutoComplete";
import CheckBoxField from "@/components/utility/CheckBoxField";
import SelectField from "@/components/utility/SelectField";
import Alert from "@/components/utility/Alert";
export default {
  name: "NewCustomer",
  components: {
    TextField,
    Autocomplete,
    CheckBoxField,
    SelectField,
    Alert
  },
  data: () => ({
    addGroupInformationForId: [111, 112, 113, 115, 116, 118, 119, 120],
    generatedKey:999,
    invalidStep3 : true,
    dropdownLanguages: ["IT", "EN", "ES", "FR", "DE", "PT"],
    selectedIban: {},
    selectedCatalog: {},
    originalPostalCodes: [],
    localPurchaseCategoriesDropdown: [],
    selectedLocation: {
      active: true,
      default: true,
      visibility: true,
    },
    mappedPostalCodes: [],
    localCustomer: {},
    step: 1,
    selectedOwnership: {}
  }),
  methods: {
    checkMail: function(stringa) {
      const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return pattern.test(stringa);
    },
    handleInput: function (obj) {

      this.generatedKey = Math.round();
      this.$forceUpdate();
      this.localCustomer[obj.field] = obj.value;
      
      switch (obj.field) {
        case "salesChannelId": 
          this.localCustomer.pendingAuthBypass = obj.value == 114; 
          break;
        case "accountingEmail":
          if (!this.localCustomer.sdiCode) { this.localCustomer.sdiCode = '0000000'; }
          break;
        case "myCustomerType":
          this.setCustomerPurchaseCategoriesDropdown();
          // this.localCustomer.potential = false;
          this.localCustomer.isPhysicalPerson = false;

          switch (obj.value) {
            case "PRIVA": 
              this.localCustomer.isPhysicalPerson = true;
              break;
            case "POTEN":
              // this.localCustomer.potential = true;
              break;
          }
          break;
        case "customerGroupIdentifierTagId":
          this.getParents();
          break;
        case "parentCode":
          let parent = this.customers.find(el => el.code == this.localCustomer.parentCode);
          if(!!parent.id) {
            this.localCustomer.defaultVatRate = parent.defaultVatRate;
            this.localCustomer.defaultPaymentTermId = parent.defaultPaymentTermId;
            this.localCustomer.defaultInvoicingCustomerId = parent.id;
            this.localCustomer.businessTypeId = parent.businessTypeId;
            this.apiCustomersIdPriceCatalogFamilyIdGet({ customerId: parent.id, familyId: this.defaultFamily}).then((res) => {
              this.selectedCatalog.familyId = this.defaultFamily;
              if (!!res.length) {
                this.selectedCatalog.priceCatalogId = res[0].priceCatalogId;
              }
            });
            this.apiCustomersIdLocationsGet(+parent.id)
            .then((response) => { 
              this.localCustomer.defaultInvoicingCustomerLocationId = response.find(x => x.default)?.id; 
            });
          }
          break;
        case "defaultInvoicingCustomerId":
          let invoicust = this.customers.find(el => el.id == this.localCustomer.defaultInvoicingCustomerId);
          if(!!invoicust.id) {
            this.localCustomer.defaultVatRate = invoicust.defaultVatRate;
            this.localCustomer.vatNumber = invoicust.vatNumber;
            this.localCustomer.defaultPaymentTermId = invoicust.defaultPaymentTermId;
            this.localCustomer.businessTypeId = invoicust.businessTypeId;
            this.apiCustomersIdPriceCatalogFamilyIdGet({ customerId: invoicust.id, familyId: this.defaultFamily}).then((res) => {
              this.selectedCatalog.familyId = this.defaultFamily;
              if (!!res.length) {
                this.selectedCatalog.priceCatalogId = res[0].priceCatalogId;
              }
            });
            this.apiCustomersIdLocationsGet(+invoicust.id)
            .then((response) => { 
              this.localCustomer.defaultInvoicingCustomerLocationId = response.find(x => x.default)?.id; 
            });
          }
          break;
        case "iban":
          this.selectedIban.fullAddress = obj.value;
          break;
        case "sdi":
          this.selectedIban.fullAddress = obj.value;
          break;
        case "country":
          if (this.selectedLocation.geonameCountryCode !== obj.value) {
            this.localCustomer.nikitaTimeZoneId = null;
          }
          this.selectedLocation.geonameCountryCode = obj.value;
          if (!!obj.value) {
            this.apiNikitaTimeZonesByCountryCountryCodeGet(obj.value).then(tzPerCountry => {
              if (tzPerCountry.length === 1) {
                this.localCustomer.nikitaTimeZoneId = tzPerCountry[0].id;
              }
            });
          }
          break;
        case "vatNumber":
          const result = this.localCustomer.vatNumber
            .replaceAll(".", "")
            .replaceAll("/", "")
            .replaceAll(",", "")
            .replaceAll("-", "");
          this.localCustomer.vatNumber = result;
          break;
        default:
      };
      this.invalidStep3 = this.passingNext(); 
      console.info(this.printError4CustomerDefaultParameters2Check(this.localCustomer));
    },
    next: function(step) {
      if(!!this.localCustomer.customerPurchaseCategoryId && this.step == 1) this.step = step;
    },
    save: function () {
      if (this.isValidRules4customerDefaultParameters2Check(this.localCustomer)) return this.showError(this.printError4CustomerDefaultParameters2Check(this.localCustomer));
      let customerId = 0;
      this.loadRes();
      this.apiCustomersPost(this.localCustomer)
      .then((res) => {
        customerId = res.id;
        if (!!this.selectedCatalog.familyId && !!this.selectedCatalog.priceCatalogId) {
          setTimeout(() => this.saveCatalog(+res.id), 500);
        }
        if(!!this.selectedIban.fullAddress) {
          setTimeout(() => this.saveIban(+res.id), 500);
        }
        if (!this.selectedLocation.geoname || !this.selectedCatalog.postalCode) {
          setTimeout(() => this.saveLocation(+res.id), 500);
        }
        if(!!this.selectedOwnership.agentId && !!this.selectedOwnership.commercialSpecialistId) {
          let opts = { 
            familyId: this.selectedOwnership.familyId ?? this.ownershipDefaultParameters.familyId ?? 1, 
            subagentId: this.selectedOwnership.subagentId,
            agentId: this.selectedOwnership.agentId ?? this.ownershipDefaultParameters.agentId, 
            supervisorAgentId: this.selectedOwnership.supervisorAgentId ?? this.ownershipDefaultParameters.supervisorAgentId,
            chiefAgentId: this.selectedOwnership.chiefAgentId ?? this.ownershipDefaultParameters.chiefAgentId, 
            commercialSpecialistId: this.selectedOwnership.commercialSpecialistId ?? this.ownershipDefaultParameters.commercialSpecialistId  
          }
          setTimeout(() => this.apiCustomersIdOwnershipFamilyIdPut({ 
            customerId: +res.id, 
            familyId: opts.familyId, 
            opts 
          }), 500);
        }
        if (!!this.localCustomer.customerGroupIdentifierTagId) {
          setTimeout(() => this.apiCustomerStatisticalDataCustomerIdTagsTagIdPut({
            id: customerId,
            tagId: this.localCustomer.customerGroupIdentifierTagId,
          }), 500);
        }
      })
      .finally(() => {
        setTimeout(() => {
          window.open(`/customers/${customerId}`, '_blank');
          setTimeout(() => {
            this.unloadRes();
            this.$router.push(`/customers`);
          }, 500)
        }, 5000);
      })
      // this.$emit("saveIn", this.localCustomer);
    },
    saveCatalog: function (customerId) {
      return this.apiCustomersIdPriceCatalogPut({ customerId: +customerId, ...this.selectedCatalog, isDefault: true, });
    },
    saveLocation: function (customerId) {
      this.selectedLocation.country =
        this.countries.find((el) => el.iso == this.selectedLocation.geonameCountryCode)?.country ||
        this.selectedLocation.country;
      if (typeof this.selectedLocation.postalCode == "number") {
        let postalCode = this.originalPostalCodes.find((el) => el.id == this.selectedLocation.postalCode);
        this.selectedLocation.city = postalCode.placeName;
        this.selectedLocation.geometry = `{ "type" : 'Point', "coordinates" : [${+postalCode.latitude}, ${+postalCode.longitude}] }`;
        this.selectedLocation.geonameRegionCode =
          this.selectedLocation.geonameRegionCode ||
          `${this.selectedLocation.geonameCountryCode}.${postalCode.adminCode1}`;
        this.selectedLocation.geonameProvinceCode =
          this.selectedLocation.geonameProvinceCode ||
          `${this.selectedLocation.geonameCountryCode}.${postalCode.adminCode1}.${postalCode.adminCode2}`;
        this.selectedLocation.postalCode = postalCode.postalCode;
      }
      this.selectedLocation.locationNumber = 0;
      return this.customerLocationsPost({ ...this.selectedLocation, customerId: +customerId });
    },
    saveIban(customerId) {
      this.selectedIban.customerId = +customerId;
      return this.apiCustomerIbansPost(this.selectedIban);
    },
    handleOwnership: function(obj) {
      this.selectedOwnership[obj.field] = obj.value;
    },
    handleLocation: function (obj) {
      this.localCustomer[obj.field] = this.selectedLocation[obj.field] = obj.value;
      switch (obj.field) {
        case "geonameCountryCode":
          if (!!this.selectedLocation.geonameCountryCode) {
            this.apiGeonameCountryIsocodeRegionsGet(this.selectedLocation.geonameCountryCode);
            this.apiNikitaTimeZonesByCountryCountryCodeGet(obj.value).then(tzPerCountry => {
              if (tzPerCountry.length === 1) {
                this.localCustomer.nikitaTimeZoneId = tzPerCountry[0].id;
              }
            });
            if (!!this.selectedLocation.geonameRegionCode)
              this.apiGeonameRegionIsocodeProvincesGet(this.selectedLocation.geonameRegionCode);
          }
          break;
        case "geonameRegionCode":
          if (!!this.selectedLocation.geonameRegionCode)
            this.apiGeonameRegionIsocodeProvincesGet(this.selectedLocation.geonameRegionCode);
          break;
        case "postalCode":
          if (!obj.value || obj.value.length < 4) this.mappedPostalCodes.length = 0;
          else if (!!obj.value && obj.value.length >= 4) {
            this.isLoadingPostalCodes = true;
            window.clearTimeout(this.timer);
            this.timer = window.setTimeout(() => {
              this.apiGeonamePostalCodeGet({
                countryIsoCode: this.selectedLocation.geonameCountryCode,
                postalCode: obj.value,
              }).then((response) => {
                this.isLoadingPostalCodes = false;
                this.selectedLocation.postalCode = response[0]?.id || this.selectedLocation.postalCode;
                this.originalPostalCodes.push(...response);

                if (typeof this.selectedLocation.postalCode == "number") {
                  let postalCode = this.originalPostalCodes.find((el) => el.id == this.selectedLocation.postalCode);
                  this.selectedLocation.geometry = `{ type : 'Point', coordinates : [${+postalCode.latitude}, ${+postalCode.longitude}] }`;
                  this.selectedLocation.geonameRegionCode = `${this.selectedLocation.geonameCountryCode}.${postalCode.adminCode1}`;
                  this.selectedLocation.geonameProvinceCode = `${this.selectedLocation.geonameCountryCode}.${postalCode.adminCode1}.${postalCode.adminCode2}`;
                }

                if (!!this.selectedLocation.geonameCountryCode)
                  this.apiGeonameCountryIsocodeRegionsGet(this.selectedLocation.geonameCountryCode);
                if (!!this.selectedLocation.geonameRegionCode)
                  this.apiGeonameRegionIsocodeProvincesGet(this.selectedLocation.geonameRegionCode);

                let mappedResponse = response.map((el) => ({
                  text: `${el.postalCode} - ${el.placeName}`,
                  value: el.id,
                }));
                this.mappedPostalCodes.push(...mappedResponse);
                this.selectedLocation.city = this.originalPostalCodes.find(
                  (el) => el.id == this.selectedLocation.postalCode
                )?.placeName;
              });
            }, 2000);
          }

          break;
        // default:
      }
    },
    handleCatalog: function (obj) {
      this.selectedCatalog[obj.field] = obj.value;
    },
    getFamiliesMapped: function () {
      return this.families.map((el) => ({ text: `${el.code} - ${el.description}`, value: el.id }));
    },
    handleIban: function (obj) {
      this.selectedIban[obj.field] = obj.value;
      if (!this.selectedIban.fullAddress)
        this.selectedIban.fullAddress = `${this.selectedIban.country}${this.selectedIban.chk}${this.selectedIban.cin}${this.selectedIban.abi}${this.selectedIban.cab}${this.selectedIban.cc}`;
      let trimmed = this.selectedIban.fullAddress.trim().replaceAll(" ", "");
      this.selectedIban.country = trimmed.substr(0, 2);
      this.selectedIban.chk = trimmed.substr(2, 2);
      this.selectedIban.cin = trimmed.substr(4, 1);
      this.selectedIban.abi = trimmed.substr(5, 5);
      this.selectedIban.cab = trimmed.substr(10, 5);
      this.selectedIban.cc = trimmed.substr(15, 12);
    },
    setCustomerPurchaseCategoriesDropdown: function () {
      this.localCustomer.customerPurchaseCategoryId = null;
      // delete this.localCustomer.customerPurchaseCategoryId;
      this.localPurchaseCategoriesDropdown.length = 0;

      switch (this.localCustomer.myCustomerType){
        case 'DISTR':
        case 'PRIVA':
          this.localCustomer.customerPurchaseCategoryId = this.customerPurchaseCategoriesDropdown
            .find(el => el.text.includes(this.localCustomer.myCustomerType)).value;
          this.localPurchaseCategoriesDropdown.push(
            ...this.customerPurchaseCategoriesDropdown.filter(el => el.text.includes(this.localCustomer.myCustomerType))
          );
          break;
        case 'POTEN':
        case 'CHAIN':
        case 'INDEP':
          this.localPurchaseCategoriesDropdown.push(
            ...this.customerPurchaseCategoriesDropdown.filter(el => !el.text.includes("DISTR") && !el.text.includes("PRIVA"))
          );
          break;
      }
    },
    getParents: function() {
      return this.customers.map(item => ({text: item.code + " - " + item.businessName, value: item.code}));
    },
    isRequiredWrapper: function(param) {
      if(!!this.localCustomer.country && this.localCustomer.country == 'IT' && param == 'sdi') return true;
      return this.isRequired(param);
    },
    passingNext: function() {
      return this.isValidRules4customerDefaultParameters2Check(this.localCustomer) || 
        !this.checkMail(this.localCustomer.email) ||
        (!!this.localCustomer.country && this.localCustomer.country == 'IT' && this.localCustomer.sdi)
    },
    clearPostalCodes: function() {
      this.mappedPostalCodes.length = 0;
      this.selectedLocation.postalCode = null;
      this.selectedLocation = { geonameCountryCode : this.selectedLocation.geonameCountryCode, active: true, default: true, visibility: true };
    },
    copyVatNumberFromParent: function() {
      if (!!this.localCustomer.parentCode) {
        const parent = this.customers.find(el => el.code === this.localCustomer.parentCode);
        if (!!parent && !!parent.vatNumber) {
          this.localCustomer.vatNumber = parent.vatNumber;
          this.$forceUpdate();
        }
      }
    },
    ...call("customers/*"),
    ...call("geoname/*"),
    ...call("families/*"),
    ...call("customerLocations/*"),
    ...call("customerIbans/*"),
    ...call("orders/*"),
    ...call("agents/*"),
    ...call("commercialSpecialists/*"),
    ...call("customerOwnerships/*"),
    ...call("priceCatalogs/*"),
    ...call("app/*"),
    ...call("vats/*"),
    ...call("utils/*")
  },
  created() {
    this.apiPurchaseCategoryGet()
    .then((resp) => {
      this.localPurchaseCategoriesDropdown.length = 0;
      this.localPurchaseCategoriesDropdown.push(...resp.map((item) => ({ text: item.description, value: item.id })));
    });
    this.selectedCatalog.familyId = this.defaultFamily;
    this.selectedOwnership.familyId = this.defaultFamily;
    this.localCustomer.defaultVatRate = this.defaultVatRateId;
    this.initBusinessTypeCollection();
    this.apiCustomerStatisticalTagGet();
    this.initCustomerCollection();
    this.initPaymentTermCollection();
    this.initPriceCatalogCollection();
    this.initCountryCollection();
    this.initFamilyCollection();
    this.initFiscalProfileCollection();
    this.initPaymentMethodCollection();
    this.initCommercialSpecialistCollection();
    this.initAgentCollection();
    this.initChannelCollection();
    this.initSectorCollection();
    this.initVatCollection();
  },
  computed: {
    emailRules() {
      const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return [
        (v) => pattern.test(v) || this.$t('invalidParam', {param:this.$t('email', {r:''})})
      ];
    },
    customerTypeCollection(){
      return [
        { value: "POTEN", text: this.$t('potential'), tooltip: "A new lead that is not currently an active customer."},
        { value: "PRIVA", text: this.$t('individual'), tooltip: "An individual (B2C)."},
        { value: "INDEP", text: this.$t('indipendent'), tooltip: "A single shop (B2B)."},
        { value: "CHAIN", text: this.$t('chain'), tooltip: "A business that owns more than one shop (B2B)."},
        { value: "DISTR", text: this.$t('distributor'), tooltip: "A business that distribute products to other B2Bs (B2B)."},
      ]
    },
    commercialSpecialistsDropdown: get("commercialSpecialists/dropdownCommercialSpecialists"),
    agentsDropdown: get("agents/dropdownAgents"),
    familiesDropdown: get("families/dropdownFamilies"),
    showSubAgent: get('tenantConfiguration/showSubAgent'),
    showFiscalProfile: get('tenantConfiguration/showFiscalProfile'),
    defaultFamily: get('tenantConfiguration/defaultFamily'),
    showFamilies: get('tenantConfiguration/showFamilies'),
    showIbanSDI: get('tenantConfiguration/showIbanSDI'),
    businessTypesDropdown: get("customers/businessTypesDropdown"),
    customerPurchaseCategoriesDropdown: get("customers/customerPurchaseCategoriesDropdown"),
    customerPurchaseCategoriesDropdownFiltered: get("customers/customerPurchaseCategoriesDropdownFiltered"),
    dropdownGroupIdentifiers: get("customers/dropdownGroupIdentifiers"),
    dropdownChannels: get("customers/dropdownChannels"),
    dropdownSectors: get("customers/dropdownSectors"),
    dropdownCustomers: get("customers/dropdownCustomers"),
    dropdownVatPercentages: get("vats/dropdownVatPercentages"),
    paymentTermsDropdown: get("orders/dropdownPaymentTerms"),
    dropdownCountries: get("geoname/dropdownCountriesIso"),
    dropdownRegions: get("geoname/dropdownRegions"),
    dropdownProvinces: get("geoname/dropdownProvinces"),
    countries: get("geoname/countries"),
    customers: get("customers/allCustomers"),
    geonameAdmin1Description: get("tenantConfiguration/geonameAdmin1Description"),
    geonameAdmin2Description: get("tenantConfiguration/geonameAdmin2Description"),
    ownershipDefaultParameters: get("tenantConfiguration/ownershipDefaultParameters"),
    defaultVatRateId: get("tenantConfiguration/defaultVatRateId"),
    priceCatalogCollection: sync("priceCatalogs/priceCatalogs"),
    families: get("families/families"),
    paymentMethodsDropdown: get("orders/dropdownPaymentMethods"),
    rules4customerDefaultParameters2Check: get("tenantConfiguration/rules4customerDefaultParameters2Check"),
    isValidRules4customerDefaultParameters2Check: get("tenantConfiguration/isValidRules4customerDefaultParameters2Check"),
    printError4CustomerDefaultParameters2Check: get("tenantConfiguration/printError4CustomerDefaultParameters2Check"),
    fiscalProfiles: sync("customers/fiscalProfiles"),
    dropdownTimezones: get("customers/dropdownTimezones"),
    isRequired: get("tenantConfiguration/isRequired"),
    priceCatalogDropdown() { return this.priceCatalogCollection.map((x) => ({ text: x.code, value: x.id })); },
    fiscalProfilesDropdown() { return this.fiscalProfiles.map((x) => ({ text: x.description, value: x.id })); },
    getPlaceholder() {
      return (param) => {
        let required = this.isRequiredWrapper(param);
        let string = param.replace('default', '').replace('Id', '').replaceAll(' ', '');//.replace( /([A-Z])/g, " $1" )
        return this.$t(`${string.charAt(0).toLowerCase() + string.slice(1)}`, {r: !!required? '*' : ''});
      }
    },
  },
};
</script>

<style scoped>
  .fixed-width { min-width: 120px !important; }
  .centered { align-items: center; display: flex; flex-direction: column; }
  .text-wrap { text-wrap: wrap; line-height: 1.3; }
</style>
